<template>
  <component :is="componentToShow"
             :to="cta ? cta.href : ''"
             :target="cta && cta.href && cta.href.toLowerCase().startsWith('http') ? '_blank' : ''"
             class="relative flex flex-col justify-end w-full max-h-[320px] h-full overflow-hidden font-medium text-white bg-cover shadow-2xl xs:h-64 lg:h-48 rounded-xl group"
             @click="handleClick"
  >
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="flex flex-col justify-end p-4 w-full h-full">
      <div v-if="backgroundImage"
           class="absolute inset-0 w-full h-full overflow-hidden rounded-xl"
           :style="{ clipPath: 'content-box' }"
      >
        <NuxtImg :src="isMobile && backgroundImageMobile ? backgroundImageMobile : backgroundImage"
                 :alt="title || ''"
                 class="object-cover w-full h-full transition duration-300 transform rounded-xl"
                 :class="cta && cta.href ? 'group-hover:scale-110' : ''"
                 sizes="xs:100vw sm:70vw md:50vw lg:30vw xl:30vw xxl:30vw"
                 preload
        />
      </div>
      <div v-else
           class="absolute inset-0 w-full h-full overflow-hidden rounded-xl bg-gradient-black-01"
           :style="{ clipPath: 'content-box' }"
      />
      <div class="absolute inset-0 w-full h-full transform rounded-xl"
           :style="{
             background: 'linear-gradient(360deg, rgba(10, 10, 10, 0.8) 4.55%, rgba(10, 10, 10, 0) 85.8%)',
           }"
      />
      <div class="relative">
        <h3 class="text-xl lg:text-base"
            v-html="title"
        />
        <div v-if="copy"
             class="mt-2 text-base lg:text-xs"
             v-html="copy"
        />
      </div>
      <ButtonBase v-if="cta"
                  :type="cta.type"
                  :label="cta.title"
                  :href-type="hrefType"
                  class="mt-6 pointer-events-none lg:mt-2"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import { useLinkType } from '../../composables/link-type'
import type { CtaType } from '../../types/button'

type CardNewsProps = {
  title?: string
  copy?: string
  cta?: CtaType
  backgroundImage: string
  backgroundImageMobile: string
}

const props = defineProps<CardNewsProps>()
const { isMobile } = useScreenSize()
const { $event: trackEvent } = useNuxtApp()

const componentToShow = computed(() => {
  if (props.cta && props.cta.href && props.cta.href.length > 0) {
    return resolveComponent('NuxtLink')
  }
  return 'div'
})

const hrefType = computed(() => {
  if (props.cta && props.cta.href && props.cta.href.length > 0) {
    return useLinkType(props.cta.href)
  }

  return null
})

function handleClick() {
  trackEvent('Card Click', {
    props: {
      ['Type']: 'news',
      ['Title']: props.title,
      ['URL']: props.cta?.href,
      ['Background Image']: props.backgroundImage
    }
  })
}
</script>
